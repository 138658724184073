<template>
  <div class="tab-hints tab-content">
    <div class="item-block">
      <div class="header no-border">
        <div class="heading-6">RecordFinder™</div>
        <gradient-banner v-if="showFreeHintsCount" class="text-sm"
          >{{ freeHintsCount }} Free Review Available</gradient-banner
        >
      </div>
      <tabs-bar ref="tabs" :start-tab="activeTab" @activate-tab="onActivateTab">
        <tabs-block-item :name="newTabName" identifier="new"> </tabs-block-item>
        <tabs-block-item :name="acceptedTabName" identifier="accepted"> </tabs-block-item>
        <tabs-block-item :name="ignoredTabName" identifier="ignored"> </tabs-block-item>
      </tabs-bar>

      <div class="content" v-if="familyTreeHintsLoadingState">
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>
      <div class="body" v-else-if="itemsLoaded">
        <person-hint-item
          v-for="hint in familyTreeHintsListState"
          :hint="hint"
          :data-clickable="hintsClickable"
          :data-visible="isHintVisible(hint)"
          :key="hint.id"
        ></person-hint-item>
        <base-pagination
          :meta="familyTreeHintsMetaState"
          :show-jump-to="false"
          class="text-md text-lg-mobile"
          @onSwitchPage="fetchHints"
        ></base-pagination>
      </div>
      <div class="content" v-if="showEmptyState">
        <div class="empty-hints" v-if="activeTab === 'new'">
          <div class="info-icon-wrapper">
            <info-icon :size="24"></info-icon>
          </div>
          <div class="title">No new matching records for {{ fullName }}</div>
          <div class="text-sm text-md-mobile">
            Increase your chances of discovering records for {{ name }} by adding details — especially dates, places,
            and more relatives.
          </div>
          <div class="buttons">
            <mcr-button class="white bold small" @click="$emit('go-to-relatives')"
              ><plus-icon :size="20" /><span>Add Relatives</span></mcr-button
            >
            <mcr-button class="bold small" @click="$emit('go-to-edit')"
              ><edit-icon :size="20" /><span>Edit Details</span></mcr-button
            >
          </div>
        </div>
        <div v-else class="default-empty-hints">You don't have any {{ activeTab }} record matches.</div>
      </div>
    </div>

    <person-saved-mentions-block
      v-if="familyTreePersonSavedMentionsState.length"
      :person-name="fullName"
      :editable="familyTreePersonState.is_write_allowed"
      :mentions="familyTreePersonSavedMentionsState"
      :meta="familyTreePersonSavedMentionsMetaState"
      :loading="familyTreePersonSavedMentionsLoadingState"
      @switch-page="reloadMentions"
    ></person-saved-mentions-block>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import McrButton from '@common/elements/buttons/mcrButton';
import GradientBanner from '@common/elements/layouts/GradientBanner';
import TabsBar from '@common/elements/tabs/TabsBar';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import InfoIcon from 'vue-material-design-icons/Information';
import EditIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/PlusCircleOutline';
import {mapGetters} from 'vuex';

import PersonHintItem from '@/components/common/tree/PersonHintItem';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

import PersonSavedMentionsBlock from './components/PersonSavedMentionsBlock.vue';

export default {
  data() {
    return {
      activeTab: this.$route.query.status || 'new',
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeHintsLoadingState',
      'familyTreeHintsListState',
      'familyTreeHintsMetaState',
      'familyTreePersonState',
      'familyTreePersonPrimaryFullnameEnState',
      'familyTreePersonPrimaryFullnameCnState',
      'familyTreePersonSavedMentionsState',
      'familyTreePersonSavedMentionsMetaState',
      'familyTreePersonSavedMentionsLoadingState',
      'userIsStaffState',
    ]),
    familyTreeId() {
      return this.$route.params.id;
    },
    personId() {
      return this.$route.params.personId;
    },
    itemsLoaded() {
      return !this.familyTreeHintsLoadingState && this.familyTreeHintsListState.length;
    },
    name() {
      const names = this.familyTreePersonState.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    fullName() {
      return this.familyTreePersonPrimaryFullnameEnState || this.familyTreePersonPrimaryFullnameCnState || UNKNOWN_NAME;
    },
    showEmptyState() {
      return !this.familyTreeHintsLoadingState && !this.familyTreeHintsListState.length;
    },
    showCountInTabName() {
      if (this.familyTreeHintsMetaState) {
        const personId = this.familyTreeHintsMetaState.person_id
          ? this.familyTreeHintsMetaState.person_id.toString()
          : null;
        return personId && this.personId && personId === this.personId;
      }
      return false;
    },
    newTabName() {
      const facets = this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.facets : {};
      const newLabel = 'New Matches';
      return this.showCountInTabName ? `${newLabel} (${facets.status.new})` : newLabel;
    },
    acceptedTabName() {
      const facets = this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.facets : {};
      return this.showCountInTabName ? `Accepted (${facets.status.accepted})` : 'Accepted';
    },
    ignoredTabName() {
      const facets = this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.facets : {};
      return this.showCountInTabName ? `Ignored (${facets.status.ignored})` : 'Ignored';
    },
    hintsClickable() {
      return this.familyTreeHintsMetaState.is_owner || this.userIsStaffState;
    },
    hintsVisible() {
      return this.familyTreeHintsMetaState ? this.familyTreeHintsMetaState.hints_visible : false;
    },
    showFreeHintsCount() {
      return this.freeHintsCount && this.hintsClickable && !this.hintsVisible;
    },
    freeHintsCount() {
      return this.familyTreeHintsMetaState.free_hints_count;
    },
  },
  methods: {
    activate() {
      this.$store.dispatch('fetchFamilyTreePersonSavedMentionsAction', {person_id: this.$route.params.personId});
    },
    isHintVisible(hint) {
      return this.hintsVisible || hint.hint_visible;
    },
    fetchHints(page) {
      const status = this.activeTab;
      const payload = {family_tree_id: this.familyTreeId, page: page || 1, status: status, person_id: this.personId};
      this.$store.dispatch('fetchFamilyTreeHintsAction', payload);
    },
    onActivateTab({tabId, fromTabId}) {
      this.activeTab = tabId;
      this.fetchHints(1);
      if (this.$route.query.status !== tabId) {
        this.$router.replace({query: {...this.$route.query, status: tabId}});
        AnalyticsMainHandler.trackHintsFilterClickEvent(tabId, getRoutePageName(this.$route));
      }
    },
    reloadMentions(page) {
      this.$store.dispatch('fetchFamilyTreePersonSavedMentionsAction', {
        person_id: this.familyTreePersonState.object_id,
        page,
      });
    },
  },
  components: {
    GradientBanner,
    PersonSavedMentionsBlock,
    PersonHintItem,
    InfoIcon,
    McrButton,
    EditIcon,
    PlusIcon,
    TabsBar,
    TabsBlockItem,
    BasePagination,
  },
  name: 'TabHints',
};
</script>

<style lang="scss" scoped>
.tab-hints {
  .tabs-bar::v-deep .tabs-header {
    box-shadow: 0 1px 0 $neutral-200;
    .tab {
      padding: 10px 16px;
    }
  }

  .person-hint-item::v-deep {
    &:first-of-type {
      border-top: none;
    }
  }
  &::v-deep .pagination {
    border-top: 1px solid $mcr-grey-tan;
    padding: 12px 16px;
    width: unset;
    margin-top: 0;
  }

  .default-empty-hints {
    text-align: center;
    color: $neutral-500;
  }

  .empty-hints {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 370px;
    margin: auto;
    padding: 16px 0;
    .info-icon-wrapper {
      border-radius: 50%;
      background: $neutral-300;
      color: $neutral-500;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
    }
    .title {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.02em;
      font-weight: 800;
      margin-bottom: 4px;
    }
    .buttons {
      display: flex;
      align-self: stretch;
      flex-grow: 1;
      column-gap: 16px;
      margin-top: 20px;
      a {
        flex-grow: 1;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .person-hint-item::v-deep {
      padding: 16px;
    }
  }
}
</style>
